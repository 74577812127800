@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .btn-primary {
    @apply bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-xl pl-2 pr-2 pt-1 pb-1 text-center cursor-pointer hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600 m-1;
  }
}


html,
body {
  @apply bg-gray-600
}


@import './components/calendar'
